import React, { useState } from "react";
import { Alert, Select } from "antd";
import { useCountries } from "use-react-countries";

const PhoneInput = (props) => {
  const { type, placeholder, formik } = props;
  const { countries } = useCountries();
  const [countrycode, setCountryCode] = useState("+966");

  let options = [];
  countries.map(({ name, flags, countryCallingCode }, index) => {
    countryCallingCode &&
      countryCallingCode !== "+972" &&
      options.push({
        key: index,
        value: countryCallingCode,
        label: (
          <div className="select-flag-container">
            <img src={flags.svg} alt={name} className="country-flag" />
            <span>{countryCallingCode}</span>
          </div>
        ),
      });
  });

  const onChange = (e) => {
    console.log(formik);
  };

  // const onSearch = (value) => {
  //   console.log('search:', value);
  // };

  return (
    <div className="form_input">
      <div className="phone-input-container">
        <p>
          <Select
            defaultValue={countrycode}
            // showSearch
            placeholder="Country"
            optionFilterProp="children"
            onChange={(value) => setCountryCode(value)}
            // onSearch={onSearch}
            // filterOption={(input, option) =>
            //   (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
            // }
            options={options}
          />
        </p>
        <input
          name={props.name}
          type={type}
          className="inputphone"
          placeholder={placeholder}
          value={formik?.values[props.name]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>
      <p>
        {formik.errors[props.name] && formik.touched[props.name] && (
          <Alert type="error" message={formik.errors[props.name]} banner />
        )}
      </p>
    </div>
  );
};
export default PhoneInput;
