import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openSidebar } from "../../data/slices/settingsSlice";
import en from "../../assets/icons/en.svg";
import loginIcon from "../../assets/icons/login.svg";
import registerIcon from "../../assets/icons/signup.svg";
import Cart from "../CartHeader/Cart";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import { toggleSidebar } from "../../data/slices/settingsSlice";
import { openModal } from "../../data/slices/modals";

const Navigation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const navigate = useNavigate();
  return (
    <div className="navigation__container">
      <div className="header__right">
        <>
          <NavLink
            className="item_header link_header"
            onClick={() => {
              dispatch(toggleSidebar());
            }}
            to="/about"
          >
            {t("header.about_as")}
          </NavLink>
          <div className="divider"></div>
          <div
            className="item_header"
            onClick={() => {
              dispatch(toggleSidebar());
              navigate("/cart");
            }}
          >
            <Cart />
          </div>
          <div className="divider"></div>

          <button
            className="item_header sign_up_header"
            onClick={() => {
              dispatch(openModal("register-modal"));
              dispatch(toggleSidebar());
            }}
          >
            <img src={registerIcon} alt="" /> {t("header.register")}
          </button>
          <div className="divider"></div>

          <button
            className="login_header"
            onClick={() => {
              dispatch(openModal("login-modal"));
              dispatch(toggleSidebar());
            }}
          >
            <img src={loginIcon} alt="" />
            {t("header.login")}
          </button>
          <div className="divider"></div>

          <button
            className="item_header lang_header"
            onClick={() => {
              if (currentLanguageCode === "en") {
                i18next.changeLanguage("ar");
              } else {
                i18next.changeLanguage("en");
              }
            }}
          >
            {t("language")} <img src={en} alt="" />
          </button>
        </>
      </div>
    </div>
  );
};

export default Navigation;
