import { createSlice } from "@reduxjs/toolkit";

const initialState = { items: [], totalQuantity: 0, isOpenCart: false };
//totalQuantity is the number of items added to the cart
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart(state, action) {
      const newItem = action.payload;
      //id item already exist
      const isExistItem = state.items.find((item) => item.id === newItem.id);
      state.totalQuantity++;
      if (!isExistItem) {
        state.items.push({
          ...newItem,
          quantity: 1,
          totalPrice: newItem.price,
          totalSize: newItem.size,
        });
      } else {
        isExistItem.quantity++;
        isExistItem.totalPrice = +isExistItem.totalPrice + +newItem.price;
        isExistItem.totalSize = +isExistItem.totalSize + +newItem.size;
      }
    },
    removeItemFromCart(state, action) {
      const id = action.payload;
      const existingItem = state.items.find((item) => item.id === id);
      state.totalQuantity--;
      if (existingItem.quantity === 1) {
        state.items = state.items.filter((item) => item.id !== id);
      } else {
        existingItem.quantity--;
        existingItem.totalPrice = existingItem.totalPrice - existingItem.price;
        existingItem.totalSize = +existingItem.totalSize - +existingItem.size;
      }
    },
  },
});

export const { addItemToCart, removeItemFromCart } = cartSlice.actions;

// export const reducer = cartSlice.reducer;

export default cartSlice.reducer;
