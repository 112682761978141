import { useState } from "react";
import { useTranslation } from "react-i18next";
import CollapseBtn from "../Collapse/Collapse";

function FilterItem({ items, title }) {
  const [isOpen, setIsOpen] = useState(true);
  const [values, setValues] = useState([]);
  const { t } = useTranslation();
  function addOrRemoveFromArray(items, val) {
    const newValues = [...items];
    const index = newValues.indexOf(val);
    if (index > -1) {
      newValues.splice(index, 1);
    } else {
      newValues.push(val);
    }
    setValues(newValues);
  }

  const getClassName = (el) => {
    if (values.includes(el)) {
      return "btn_collapse clicked";
    } else {
      return "btn_collapse";
    }
  };

  const selectAll = (items) => {
    if (items.length === values.length) {
      setValues([]);
    } else {
      setValues(items);
    }
  };

  return (
    <div className="product_type_filter">
      <div className="nav_filter">
        <p className="title">{t(`filter_nav.${title}`)}</p>
        <div className="collapse_items">
          <p
            className={
              values.length === items.length
                ? "btn_select_all clicked_all"
                : "btn_select_all"
            }
            onClick={() => selectAll(items)}
          >
            {values.length === items.length
              ? t("filter_nav.unselect_all")
              : t("filter_nav.Select_all")}
          </p>
          <span className="point"></span>
          <CollapseBtn
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            text={isOpen ? t("filter_nav.show_less") : t("filter_nav.show_all")}
          />
        </div>
      </div>
      {isOpen && (
        <div className="content">
          <div className="content_collapse">
            {items.map((el, index) => (
              <button
                className={getClassName(el)}
                key={index}
                onClick={() => addOrRemoveFromArray(values, el)}
              >
                {t(`${el.label}`)}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default FilterItem;
