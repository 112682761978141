import "./_Logo.scss";
import cookies from "js-cookie";

function Logo({ img }) {
  const currentLanguageCode = cookies.get("i18next") || "en";

  return (
    <a style={{ cursor: "pointer" }} href="/">
      <img
        className={
          currentLanguageCode === "en" ? "img_logo logo_en" : "img_logo logo_ar"
        }
        src={img}
        alt=""
      />
    </a>
  );
}

export default Logo;
