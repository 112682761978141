import collapse from "../../../../assets/icons/collapse.svg";
import collapseUp from "../../../../assets/icons/collaps-up.svg";

function CollapseBtn({ text, setIsOpen, isOpen }) {
  return (
    <>
      <div className="Collapse_btn" onClick={() => setIsOpen(!isOpen)}>
        <p>{text}</p>
        {isOpen ? (
          <img src={collapse} alt="" />
        ) : (
          <img src={collapseUp} alt="" />
        )}
      </div>
    </>
  );
}

export default CollapseBtn;
