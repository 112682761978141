import React, { useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "./Carousel.scss";
import product from "../../../assets/images/detailProduct.png";

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  return (
    <div className="carousel">
      <div className="navigation-wrapper">
        <div ref={sliderRef} className="keen-slider">
          <div className="keen-slider__slide number-slide1">
            <img src={product} className="image" alt="err_product" />
          </div>
          <div className="keen-slider__slide number-slide1">
            <img src={product} className="image" alt="err_product" />
          </div>
          <div className="keen-slider__slide number-slide1">
            <img src={product} className="image" alt="err_product" />
          </div>
          <div className="keen-slider__slide number-slide1">
            <img src={product} className="image" alt="err_product" />
          </div>
          <div className="keen-slider__slide number-slide1">
            <img src={product} className="image" alt="err_product" />
          </div>
        </div>
      </div>
      {loaded && instanceRef.current && (
        <div className="dots">
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              ></button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Carousel;
