function Button(props) {
  return (
    <button
      className="normal_btn"
      type={props.type}
      onClick={props?.onClick || null}
    >
      {props.children}
    </button>
  );
}

export default Button;
