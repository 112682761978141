import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import { openModal } from "../../data/slices/modals";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputText from "../../components/InputGenerator/InputText/InputText";
import pwdSvg from "../../assets/icons/pwd.svg";
import PhoneNumber from "../../components/InputGenerator/PhoneInput/PhoneInput";
import { useNavigate } from "react-router-dom";

function Login({ id, open, handleClose, data, ...rest }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().min(2, "Too Short!").max(70, "Too Long!"),
    }),
    onSubmit: (values) => {
      console.log(values);
      // if (!isEdit) {
      //   dispatch(createUser(values))
      //     .unwrap()
      //     .then((res) => {
      //       message.success(`User created successfully.`);
      //       dispatch(closeModal("user-modal"));
      //     })
      //     .catch((err) => {
      //       message.error(err.message || "something went wrong");
      //     });
      // } else {
      //   const { password } = values;
      //   if (password)
      //     dispatch(updateUserPassword({ password, id }))
      //       .unwrap()
      //       .then((res) => {
      //         message.success(`User password updated successfully.`);
      //       })
      //       .catch((err) => {
      //         message.error(err.message || "something went wrong");
      //       });
      //   dispatch(updateUser({ values, id }))
      //     .unwrap()
      //     .then((res) => {
      //       message.success(`User updated successfully.`);
      //       dispatch(closeModal("user-modal"));
      //     })
      //     .catch((err) => {
      //       message.error(err.message || "something went wrong");
      //     });
      // }
    },
  });
  let fields = [
    {
      name: "password",
      type: "password",
      placeholder: t("login.passwod_playcholder"),
      prefix: pwdSvg,
    },
    {
      name: "phone",
      type: "number",
      placeholder: t("profile.phone_number"),
    },
  ];

  return (
    <div className="login_modal">
      <Modal
        wrapClassName="modal_login_antd"
        open={open}
        onCancel={() => {
          handleClose(id);
        }}
        width="404px"
        footer={false}
      >
        <form action="" onSubmit={formik.handleSubmit}>
          <p className="title">{t("login.title")}</p>
          <p className="welcome">{t("login.welcome")}</p>
          <p className="text1">{t("login.text1")}</p>
          <div className="inputs">
            {/* <PhoneInput field={fields[1]} /> */}
            <PhoneNumber
              type="number"
              placeholder={fields[1].placeholder}
              label="Phone Number"
              name="phone"
              formik={formik}
            />
            <InputText formik={formik} field={fields[0]} />
          </div>
          <p className="forget_pwd">{t("login.forget_password")}</p>
          <Button
            type="submit"
            onClick={() => {
              navigate("/profile");
              handleClose(id);
            }}
          >
            {t("login.Login_btn")}
          </Button>
          <p className="without_cmpt">
            {t("login.do_not_have_account")}{" "}
            <span
              className="signup"
              onClick={() => {
                handleClose(id);
                dispatch(openModal("register-modal"));
              }}
            >
              {t("register.Register_btn")}
            </span>
          </p>
        </form>
      </Modal>
    </div>
  );
}

export default Login;
