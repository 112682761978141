import { useSelector, useDispatch } from "react-redux";
import { closeFilter } from "../../data/slices/settingsSlice";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import useWindowSize from "../../hooks/useWindowSize";
import Filter from "../../views/Home/components/Filter/Filter";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const CloseSidebarTrigger = () => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  if (width >= 1090) return null;
  return (
    <div
      className="close_sidebar_trigger"
      onClick={() => dispatch(closeFilter())}
    >
      <CloseIcon />
    </div>
  );
};
const MobileFilter = () => {
  const { isFilterMenuOpened } = useSelector((state) => state.settings);
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();

  const { width } = useWindowSize();
  if (width > 1090) {
    return null;
  }
  return (
    <div
      className={
        isFilterMenuOpened === true &&
        width < 1090 &&
        currentLanguageCode === "en"
          ? "filter_mobile open"
          : isFilterMenuOpened === true &&
            width < 1090 &&
            currentLanguageCode === "ar"
          ? "filter_mobile_ar open_filter_ar"
          : currentLanguageCode === "en"
          ? "filter_mobile"
          : currentLanguageCode === "ar" && "filter_mobile_ar"
      }
    >
      <div className="sidebar__group">
        <div className="logo__container">
          <a className="menu_title" style={{ cursor: "pointer" }} href="/">
            {t("filter_search")}
          </a>
          <CloseSidebarTrigger />
        </div>
        <Filter />
      </div>
    </div>
  );
};

export default MobileFilter;
