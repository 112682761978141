import Demo from "../../../../components/Slider/Slider";
import { useState } from "react";

import FilterItem from "../FilterItem/FilterItem";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../../hooks/useWindowSize";

function Filter() {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const filterTypes = {
    title: "product_type",
    items: [
      {
        id: 1,
        value: "Tomato",
        label: "Tomato",
      },
      {
        id: 2,
        value: "Tomato",
        label: "Tomato",
      },
      {
        id: 3,
        value: "Tomato",
        label: "Tomato",
      },
    ],
  };

  const filterMarket = {
    title: "Origin_Market",
    items: [
      {
        id: 1,
        value: "Riyadh",
        label: "Riyadh",
      },
      {
        id: 2,
        value: "Jeddah",
        label: "Jeddah",
      },
      {
        id: 3,
        value: "Al Khobar",
        label: "Al Khobar",
      },
      {
        id: 4,
        value: "Al Dammam",
        label: "Al Dammam",
      },
    ],
  };

  const filterAvailability = {
    title: "Availability",
    items: [
      {
        id: 1,
        value: "On Demand",
        label: "On Demand",
      },
      {
        id: 2,
        value: "Available",
        label: "Available",
      },
    ],
  };

  return (
    <div className="filter">
      <div className="slider_filter">
        <p className="filter_text">{t("filter_nav.price")}</p>
        <Demo />
      </div>
      <div className="divider"></div>
      {/* type filter  */}
      <FilterItem items={filterTypes.items} title={filterTypes.title} />
      <div className="divider"></div>
      <FilterItem items={filterMarket.items} title={filterMarket.title} />
      <div className="divider"></div>
      <FilterItem
        items={filterAvailability.items}
        title={filterAvailability.title}
      />
      {width < 1089 && (
        <div className="action_filter">
          <button className="btn_1">{t("filter_nav.apply_filter")}</button>
          <button className="btn_2">{t("filter_nav.cancel")}</button>
        </div>
      )}
    </div>
  );
}

export default Filter;
