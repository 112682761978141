import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import closeModal from "../../../assets/icons/close.svg";
import AddProduct from "../../../assets/icons/v3.svg";
import removeProduct from "../../../assets/icons/v1.svg";
import { useTranslation } from "react-i18next";
import Carousel from "./Carousel";
import Collapse from "./Collapse";
import { useDispatch } from "react-redux";
import { addItemToCart } from "../../../data/slices/cartSlice";
import { useEffect } from "react";

const ProductModal = ({ id, open, handleClose, data, ...rest }) => {
  const [nbrProduct, setNbrProduct] = useState(2);
  const [newProduct, setNewProduct] = useState(data?.product);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const addToCart = () => {
    for (let i = 0; i < newProduct.quantity; i++) {
      dispatch(addItemToCart(data?.product));
    }
    handleClose(id);
  };
  useEffect(() => {
    setNewProduct({ ...data?.product, quantity: 1 });
  }, [data?.product]);

  const setSize = (action) => {
    const item = { ...newProduct };
    if (action === "add") {
      item.size = +item.size + 1;
      item.quantity = +item.quantity + 1;
      item.price = +item.price + +data?.product?.price;
    } else if (action === "remove" && item?.quantity > 1) {
      item.size = +item.size - 1;
      item.quantity = +item.quantity - 1;

      item.price = +item.price - +data?.product?.price;
    }
    return setNewProduct(item);
  };

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="close-btn" onClick={() => handleClose(id)}>
          <img src={closeModal} alt="close_modal_err" />
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <div className="modal-content-product">
          <Carousel />
          <div className="model-content">
            <div className="modal-title">
              <span className="product-title"> {data?.product?.name}</span>
              <span className="product-price">
                {data?.product?.price} {t("filter_nav.r_s")}
              </span>
            </div>
            <div className="descreption-product">
              <span className="product-Weight-detail">
                <span className="description">{t("products.Weight")}</span>
                <span className="product-nbr-by-kilo">
                  {data?.product?.size} {t("products.kg")}
                </span>
              </span>
              <span className="product-conetent-by-kilo">
                {t("products.per_kilo")}
              </span>
            </div>
            <div className="product-market-detail">
              <span className=" description product-market">
                {t("products.Market")}
              </span>
              <span className="product-Dammam">Al Dammam</span>
            </div>
            {data?.product?.isAvailable ? (
              <div className="product-price-detail">
                <div className="product-price-description">
                  <span className="description product-price-title">
                    {t("products.price")}
                  </span>
                  <span className="product-price">
                    2.99 {t("products.sar")}
                  </span>
                </div>
                <div className="count-product">
                  <div
                    className="add-product"
                    onClick={() => {
                      setSize("add");
                    }}
                  >
                    <img src={AddProduct} alt="add_product_err" />
                  </div>
                  <div className="nbr-product">{newProduct?.quantity}</div>
                  <div
                    className="remove-product"
                    onClick={() => {
                      setSize("remove");
                    }}
                  >
                    <img src={removeProduct} alt="remove_product_err" />
                  </div>
                </div>
              </div>
            ) : (
              <p className="description-nothave-product">
                {t("products.availability_msg")}
              </p>
            )}
            <div className="product-price-info">
              {data?.product?.isAvailable ? (
                <></>
              ) : (
                <div className="product-price-description">
                  <span className="product-price-title">
                    {t("products.price")}
                  </span>
                  <span className="product-price">
                    2.99 {t("products.sar")}
                  </span>
                </div>
              )}
              <Collapse product={newProduct} />
              {data?.product?.isAvailable && (
                <>
                  <div className="product-total-price-detail">
                    <span className="product-total-price-title">
                      {t("products.Total_Price")}
                    </span>
                    <span className="product-total-price-ors">
                      {newProduct?.price}
                      {t("products.sar")}
                    </span>
                  </div>
                  <button className="btn-addcart" onClick={addToCart}>
                    {t("products.Add_to_Cart")}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProductModal;
