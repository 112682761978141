import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import PhoneNumber from "../../components/InputGenerator/PhoneInput/PhoneInput";
import { openModal } from "../../data/slices/modals";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

const Register = ({ id, open, handleClose, data, ...rest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let field = {
    name: "phone",
    type: "number",
    placeholder: t("profile.phone_number"),
  };
  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: (values) => {
      console.log(values);
    },
  });
  return (
    <div className="login_modal">
      <Modal
        wrapClassName="modal_login_antd"
        open={open}
        onCancel={() => {
          handleClose(id);
        }}
        width="404px"
        footer={false}
      >
        <p className="title">{t("register.title")}</p>
        <p className="welcome">{t("register.welcome")}</p>
        <p className="text1">{t("register.text1")}</p>
        <div className="inputs">
          <PhoneNumber
            type="number"
            placeholder={field.placeholder}
            label="Phone Number"
            name="phone"
            formik={formik}
          />
          <Button
            onClick={() => {
              handleClose(id);
              navigate("phone-verification");
            }}
          >
            {t("register.Register_btn")}
          </Button>
        </div>

        <p className="without_cmpt">
          {t("register.have_account")}{" "}
          <span
            className="signup"
            onClick={() => {
              handleClose(id);
              dispatch(openModal("login-modal"));
            }}
          >
            {t("login.Login_btn")}
          </span>
        </p>
      </Modal>
    </div>
  );
};

export default Register;
