import HamburgerMenuTrigger from "../HamburgerMenuTrigger";
import useWindowSize from "../../hooks/useWindowSize";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { openSidebar } from "../../data/slices/settingsSlice";
import en from "../../assets/icons/en.svg";
import loginIcon from "../../assets/icons/login.svg";
import registerIcon from "../../assets/icons/signup.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Cart from "../CartHeader/Cart";
import cookies from "js-cookie";
import { openModal } from "../../data/slices/modals";
import logoImg from "../../assets/images/bwLogo.png";
import Logo from "../Logo/Logo";
const Header = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentLanguageCode = cookies.get("i18next") || "en";
  return (
    <>
      <header className="header">
        {/* <NavLink to="/" className="header__group">
          {t("header.logo")}
        </NavLink> */}
        {/* <a style={{ cursor: "pointer" }} href="/">
          <img
            className={
              currentLanguageCode === "en"
                ? "img_logo logo_en"
                : "img_logo logo_ar"
            }
            src={logoImg}
            alt=""
          />
        </a> */}
        <Logo img={logoImg} />

        <div className="header__right">
          {width > 1090 && (
            <>
              <NavLink className="item_header link_header" to="/about">
                {t("header.about_as")}
              </NavLink>
              <div className="item_header">
                <Cart />
              </div>
              <button
                className="item_header sign_up_header"
                onClick={() => dispatch(openModal("register-modal"))}
              >
                <img src={registerIcon} alt="" /> {t("header.register")}
              </button>
              <button
                className="login_header"
                onClick={() => dispatch(openModal("login-modal"))}
              >
                <img src={loginIcon} alt="" />
                {t("header.login")}
              </button>
              <button
                className="item_header lang_header"
                onClick={() => {
                  if (currentLanguageCode === "en") {
                    i18next.changeLanguage("ar");
                  } else {
                    i18next.changeLanguage("en");
                  }
                }}
              >
                {t("language")}
                <img src={en} alt="" />
              </button>
            </>
          )}
          {width < 1091 && <div></div>}
          <HamburgerMenuTrigger />
        </div>
      </header>
    </>
  );
};

export default Header;
