import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { removeItemFromCart } from "../../../../data/slices/cartSlice";
function DeleteModal({ id, open, handleClose, data, ...rest }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const removeFromCart = () => {
    dispatch(removeItemFromCart(data?.id));
    handleClose(id);
  };
  return (
    <div className="delete_modal">
      <Modal
        wrapClassName="delete_modal"
        open={open}
        onCancel={() => {
          handleClose(id);
        }}
        width="260px"
        footer={false}
      >
        <p className="title_delete">{t("cart.Delete_Order")}</p>
        <p className="subtitle_delete">
          {t("cart.Are_you_sure_to_delete_order")}
        </p>
        <p className="question">{data?.name} ?</p>
        <div
          className="actions"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <button
            className="cancel_delete"
            onClick={() => {
              handleClose(id);
            }}
          >
            {t("cart.Cancel")}
          </button>
          <button className="cancel_delete d_btn" onClick={removeFromCart}>
            {t("cart.Confirm")}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default DeleteModal;
