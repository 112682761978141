import { useState } from "react";
import { Slider } from "antd";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

const Demo = () => {
  const [values, setValues] = useState([0, 70]);
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";

  return (
    <div>
      <Slider
        reverse={currentLanguageCode === "ar"}
        range
        defaultValue={[0, 70]}
        onChange={(v) => setValues(v)}
      />
      <div className="values_slider">
        <p>
          {values[0]} {t("filter_nav.r_s")}
        </p>
        <p>
          {values[1]} {t("filter_nav.r_s")}
        </p>
      </div>
    </div>
  );
};

export default Demo;
