import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import cart from "../../assets/icons/shopping.svg";
import cookies from "js-cookie";
import { useSelector } from "react-redux";

function Cart() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { totalQuantity } = useSelector((state) => state.cart);

  return (
    <div className="item_header cart_header" onClick={() => navigate("/cart")}>
      <img src={cart} alt="" /> {t("header.cart")}
      <p
        className={
          currentLanguageCode === "ar"
            ? "number_product a_r"
            : "number_product e_n"
        }
      >
        {totalQuantity}
      </p>
    </div>
  );
}

export default Cart;
